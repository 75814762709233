import { Layout } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { HashRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import MarkdownComponent from "./MarkdownComponent";

interface State {
  // TODO?
}

export default class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Layout
        style={{
          paddingBottom: 10,
          backgroundColor: "transparent",
        }}
      >
        <ToastContainer position="top-right" />
        <Layout.Content
          style={{ marginLeft: 10, marginRight: 10, padding: "0 50px" }}
        >
          <HashRouter>
            <div className="App" style={{ overflowY: "auto" }}>
              <ToastContainer position="top-right" />
              <Route path="/**" component={MarkdownComponent} />
              <footer
                style={{
                  paddingTop: 10,
                  marginBottom: 5,
                  paddingBottom: 10,
                  textAlign: "center",
                  backgroundColor: `#e3e3e3`,
                }}
              >
                Keith MacKay ©2020
              </footer>
            </div>
          </HashRouter>
        </Layout.Content>
      </Layout>
    );
  }
}
