import axios from "axios";
import React from "react";
import Markdown from "react-markdown/with-html";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface State {
  contents?: string;
}

interface Props extends RouteComponentProps<any> {}

export default withRouter(
  class MarkdownComponent extends React.Component<Props, State> {
    private interval?: NodeJS.Timeout;

    constructor(props: Props) {
      super(props);
      this.state = {
        contents: `Loading`,
      };
    }

    private setLoading = (after?: () => void) => {
      this.setState(
        (prev) => ({
          ...prev,
          contents: `# Loading\n<br/>This Page is loading`,
        }),
        after
      );
    };

    public componentWillUpdate(nextProps: Props, nextState: State) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        setTimeout(() => {
          this.setLoading(this.load);
        }, 100);
      }
    }

    public componentWillUnmount() {
      if (!!this.interval) {
        clearInterval(this.interval);
      }
    }

    public componentDidMount() {
      this.setLoading(this.load);
      this.interval = setInterval(this.load, 10000);
    }

    public render() {
      return (
        <div className="markdown">
          {this.state.contents && (
            <Markdown escapeHtml={false} source={this.state.contents} />
          )}
        </div>
      );
    }

    private load = () => {
      axios
        .get(`https://files.keith.sh/${this.props.location.pathname}`)
        .then((r) => r.data)
        .then((data: string) => {
          this.setState((prev) => ({ ...prev, contents: data }));
        })
        .catch((err) => {
          console.error(err);
          this.setState((prev) => ({
            ...prev,
            contents: `# <strong style="color:red">Failed to Load</strong>`,
          }));
        });
    };
  }
);
